<template>
  <div class="">
    <sports
        v-if="menuCode === 'SPORTS_GAME' ||
          menuCode === 'MATCH' ||
          menuCode === 'CROSS' ||
          menuCode === 'HANDICAP' ||
          menuCode === 'LIVE' ||
          menuCode === 'SPECIAL' ||
          menuCode === 'VSOCCER' ||
          menuCode === 'VBASKETBALL'"
        :myBetList="bettingList"
        :menuCode="menuCode"
        v-on:cancelBetItem="cancelBetItem"
    >
    </sports>
    <power-ball
        v-else-if="menuCode === 'POWERBALL' || menuCode === 'POWERBALL3M' || menuCode ==='SPEEDKENO'"
        :myBetList="bettingList"
        v-on:cancelBetItem="cancelBetItem"
    >
    </power-ball>
    <v-dog-racing
        v-else-if="menuCode === 'VDOGRACING'"
        :myBetList="bettingList"
        v-on:cancelBetItem="cancelBetItem"
    >
    </v-dog-racing>
    <casino
        v-else-if="menuCode === 'CASINO_LIVE' || menuCode === 'CASINO_SLOT' || menuCode === 'CASINO'"
        :myBetList="bettingList"
        v-on:cancelBetItem="cancelBetItem"
        v-on:callResult="callResult"
        v-on:getRoundDetail="getRoundDetail"
    >
    </casino>
  </div>
</template>

<script>
import Sports from "@/components/Sport/BetHistory/GameType/Sports/Pc";
import PowerBall from "@/components/Sport/BetHistory/GameType/PowerBall/Pc";
import VDogRacing from "@/components/Sport/BetHistory/GameType/DogRacing/Pc";
import Casino from "@/components/Sport/BetHistory/GameType/Casino/Pc";

export default {
  name: "BoardPc",
  data() {
    return {
      bettingList: [],
    }
  },
  props: {
    myBetList: {
      type: Array
    },
    menuCode: {
      type: String
    }
  },
  components: {
    Sports,
    PowerBall,
    VDogRacing,
    Casino
  },
  methods: {
    openDetail: function (list) {
      this.bettingList = list
      console.log('betlist', this.bettingList)
    },
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
    callResult: function(product,game){
      this.$emit('callResult',product,game)
    },
    getRoundDetail: function(id){
      this.$emit('getRoundDetail',id)
    }
  },
  mounted() {
  },

}
</script>

<style scoped>
th, td {
  font-size: 14px !important;
}

.win > td {
  background-color: #f7f4f214;
  color: #FFFFFF;
}

.lose > td {
  background-color: rgba(245, 241, 238, 0.08);
  color: #8d7f7f;
}



button {
  margin-right: 5px;
}


tr:hover {
  background-color: transparent !important;

}


.dog {
  margin: 2px;
  padding: 5px;
  border: 1px solid #6e6e6e;
}

.dog div:nth-child(2n+1) {

}

.dog div:nth-child(2n) {
  min-width: 40px;
}
</style>