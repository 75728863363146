<template>
  <v-simple-table class="mb-4">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center" style="width:15%">
          분류
        </th>
        <th class="text-center" style="width:15%">
          게임사
        </th>
        <th class="text-center" style="width:20%">
          라운드ID
        </th>
        <th class="text-center" style="width:20%">
          테이블IO
        </th>
        <th class="text-center" style="width:10%">
          TransId
        </th>
        <th class="text-center" style="width:10%">
          처리요청
        </th>
      </tr>
      </thead>
      <tbody>
      <!--당첨-->
      <tr
          v-for="(betItem,i) in myBetList"
          :key="i"
          class="game-info">

        <td class="text-center">
          {{ betItem.gameCodeText }}
        </td>
        <Td class="text-center">
          {{ betItem.leagueIcon }}
        </td>
        <td class="text-center">
          {{ betItem.teamHome }}
        </td>
        <td class="text-center">
          {{ betItem.team4 }}
        </td>
        <td class="text-center">
          {{ betItem.teamAway }}
        </td>
        <td class="text-center">
          <v-btn
              outlined
              style="font-size:11px;"
              color="red"
              @click="getRoundDetail(betItem.teamHome)"
              v-if="betItem.status !== 'READY'"
          >
            라운드상세
          </v-btn>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "SportsPc",
  props: {
    myBetList: {
      type: Array
    },

  },
  methods: {
    cancelBetItem: function (id) {
      this.$emit('cancelBetItem', id)
    },
    callResult: function(item){
      this.$emit('callResult',item)
    },
    getRoundDetail: function(id){
      this.$emit('getRoundDetail',id)
    }


  },

}
</script>

<style scoped>
th, td {
  font-size: 14px !important;
}


.win > td {
  background-color: #f7f4f214;
  color: #FFFFFF;
}

.lose > td {
  background-color: rgba(245, 241, 238, 0.08);
  color: #8d7f7f;
}


button {
  margin-right: 5px;
}


tr:hover {
  background-color: transparent !important;

}



.dog {
  margin:2px;
  padding:5px;
  border:1px solid #6e6e6e;
}

.dog div:nth-child(2n+1){

}
.dog div:nth-child(2n){
  min-width:40px;
}
</style>